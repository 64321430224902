<template>
    <div class="border-u-bottom">
        <b-container>
            <b-navbar variant="faded" type="u-navbar">
                <b-navbar-brand href="/" class="p-0 my-auto mr-auto">
                    <img src="/img/icon/vtb-logo.svg"
                         alt="Банк ВТБ — российский универсальный коммерческий банк c государственным участием"
                    >
                </b-navbar-brand>
                <b-nav class="ml-auto d-none d-lg-flex">
                    <b-nav-item v-for="(tab, index) in tabs"
                                :key="tab.title + index"
                                :class="{ active: index === activeItem}"
                                @click="selectTab(tab,index)"
                                v-scroll-to="{ el: '#scroll-from-tab-form', offset: -150}"
                    >
                        {{tab.title}}
                    </b-nav-item>

                    <b-nav-item v-scroll-to="{el:'#scroll-from-main-list', offset: -25}">Открыть счет</b-nav-item>
                </b-nav>

                <b-nav-item-dropdown class="d-block d-lg-none" right>
                    <template #button-content>
                        <div class="burger">
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </template>
                    <b-dropdown-item v-for="(tab, index) in tabs"
                                     :key="tab.title + index"
                                     :class="{ active: index === activeItem}"
                                     @click="selectTab(tab,index)"
                                     v-scroll-to="'#scroll-from-tab-form'"
                    >
                        {{tab.title}}
                    </b-dropdown-item>
                    <b-dropdown-item v-scroll-to="'#scroll-from-main-list'">Открыть счет</b-dropdown-item>
                </b-nav-item-dropdown>

            </b-navbar>
        </b-container>
    </div>
</template>

<script>
    import {BusinessFormTabs} from "../../BusinessForms";
    import TabFormClass from "../../classes/tabForm";

    export default {
        name: "Menu",
        computed: {
            /**
             * @return BusinessFormTabs
             **/
            tabs() {
                return BusinessFormTabs;
            },
        },
        data() {
            return {
                activeItem: null,
            };
        },
        methods: {
            /**
             * Генерирует событие tab-was-selected,
             * передает в кач-ве аргумента: this.tabLocal,
             */
            selectTab(tab, index) {
                this.$root.$emit('show-preloader');
                this.$root.$emit('tab-was-selected', tab, index);
                this.$root.$emit('hide-preloader');
            },
            selectTabHandler(tab, index) {
                this.activeItem = index;
            },
        },
        mounted() {
            this.$root.$on('tab-was-selected', this.selectTabHandler);
        }
    }
</script>

<style lang="scss">
    .border {
        &-u {
            &-bottom {
                border-bottom: 1px solid #DCE0EB;
            }
        }
    }

    .navbar {
        &-u-navbar {
            padding: 0;
            @include _lg {
                padding: 13px 0;
                img {
                    max-height: 28px;
                }
            }

            .nav {
                &-item {
                    a {
                        font-size: 16px;
                        line-height: 18px;

                        color: #2F3441;
                        padding: 45px 20px;
                    }

                    &.active {
                        a {
                            background: rgba(235, 238, 247, 0.2);
                            border-bottom: 3px solid #002882;
                        }
                    }

                    &.dropdown {
                        list-style: none;
                        outline: none;

                        .dropdown {
                            &-toggle {
                                padding: 0;
                                outline: none;

                                &:after {
                                    content: none;
                                }
                            }

                            &-menu {
                                margin: 0;
                                padding: 0;
                                top: -9px;
                                right: 0;
                                box-shadow: 0 4px 15px rgba(176, 196, 219, 0.25);
                                border-radius: 2px;
                                border: none;

                                li {
                                    a {
                                        padding: 10px 15px;
                                        border-bottom: 1px solid $gray;

                                        &:active {
                                            background: $site-color;
                                            color: white;
                                        }
                                    }

                                    &:last-child {
                                        a {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }

                        .burger {
                            width: 27px;
                            position: absolute;
                            top: calc(50% - 7px);
                            left: auto;
                            right: 0;
                            transform: translate(-50%);

                            span {
                                width: 100%;
                                height: 2px;
                                display: block;
                                margin: 0 0 5px 0;
                                background: $site-color;

                                &:nth-child(1) {
                                    transform: rotate(0deg);
                                    transition: transform 0.3s ease-out;
                                }

                                &:nth-child(2) {
                                    width: 100%;
                                    opacity: 1;
                                    transform: translatex(0);
                                    transition: all 0.3s ease-out .4s;
                                }

                                &:nth-child(3) {
                                    transform: rotate(0deg);
                                    transition: transform 0.3s ease-out;
                                }
                            }
                        }

                        &.show {
                            .burger {
                                span {
                                    &:nth-child(1) {
                                        transform: rotate(45deg) translate(5px, 10px);
                                        transition: transform .3s ease-out .3s;
                                    }

                                    &:nth-child(2) {
                                        width: 0;
                                        opacity: 0;
                                        transform: translatex(-10px);
                                        transition: all 0.5s ease-out;
                                    }

                                    &:nth-child(3) {
                                        transform: rotate(-45deg) translate(0px, -5px);
                                        transition: transform .3s ease-out .3s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

</style>